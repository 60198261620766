.background {
  font-size: 18px;
  max-width: 1200px;
  padding: 16px;
  color: #385061;
  line-height: 1.5rem;
  margin: 0 auto;
  text-align: justify;
}

.background header {
  color: gray;
  font-size: 0.8em;
}

.background header h2 {
  text-align: center;
  color: #385061;
}

.background h1 {
  text-align: center;
}

.background ol {
  counter-reset: list;
}

.background ol>li {
  list-style: none;
}

.background ol>li::before {
  content: '（' counter(list) '）';
  counter-increment: list;
}
