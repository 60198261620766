.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);

  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 3rem;
}
