.background {
  background-color: white;
}

.logo {
  background-color: white;
  width: 80px;
  height: 80px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.download-box {
  max-width: 520px;
}

.app-store {
  display: block;
  height: 60px;
  user-select: none;
  aspect-ratio: 109 / 40;
}

.android-download {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  width: auto;
  height: 60px;
  min-width: 163.5px;
  padding: 0 12px;
  color: white;
  font-size: 22px;
  background-color: black;
  border: 1px solid #a6a6a6;
  border-radius: 10px;
}

.android-download-icon {
  font-size: 32px;
}

.preview-box {
  max-width: 520px;
}

.preview-img {
  width: 100%;
}

.features {
  list-style-type: none;
  padding-inline-start: 0;
}

.features li {
  margin-top: 32px;
  text-align: center;
  font-size: 14px;
}
